import React, { useState } from "react";
import Select from "react-select";
import beerTypes from "./beerTypes";
import PubsSelect from "./PubsSelect";
import { getDatabase, ref, push } from "firebase/database";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY; 
const MAPBOX_API_ENDPOINT = "https://api.mapbox.com/geocoding/v5/mapbox.places/";

const BeerPriceForm = () => {
  const [selectedPub, setSelectedPub] = useState(null);
  const [price, setPrice] = useState("");
  const [selectedBeerType, setSelectedBeerType] = useState(null);
  const [beerGarden, setBeerGarden] = useState(false);
  const [liveSports, setLiveSports] = useState(false);
  const [liveMusic, setLiveMusic] = useState(false);
  const [error, setError] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [pubError, setPubError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [beerTypeError, setBeerTypeError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);
  const [showPriceError, setShowPriceError] = useState(false);
  const [happyHour, setHappyHour] = useState(false);
  const [happyHourStart, setHappyHourStart] = useState(''); // New state for happy hour start time
  const [happyHourEnd, setHappyHourEnd] = useState(''); // New state for happy hour end time
  const [happyHourStartPeriod, setHappyHourStartPeriod] = useState('PM'); // AM/PM for start time
  const [happyHourEndPeriod, setHappyHourEndPeriod] = useState('PM'); // AM/PM for end time
  const [happyHourDays, setHappyHourDays] = useState([]); // New state for happy hour days

  const daysOfWeek = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' },
  ];


  const formatAddress = (pub) => {
    // Clean and format the address, including postcode if available
    const { pubName, roadName, town, postcode } = pub;
    const addressParts = [
      pubName.trim(),
      roadName.trim(),
      town ? town.trim() : '',
      postcode ? postcode.trim() : '',
      'UK'
    ].filter(Boolean); // Filter out any empty strings
    return addressParts.join(', ');
  };

  const handleRecaptchaChange = (response) => {
    setRecaptchaError(false);
    setRecaptchaResponse(response);
  };

  const handlePubChange = (selectedOption) => {
    setSelectedPub(selectedOption);
    setPubError(false);
  };

  const handlePriceChange = (event) => {
    const enteredPrice = parseFloat(event.target.value);
    if (isNaN(enteredPrice)) {
      setPrice("");
      setPriceError(false);
    } else if (enteredPrice >= 1 && enteredPrice <= 20) {
      setPrice(enteredPrice);
      setPriceError(false);
    } else {
      setPrice("");
      setPriceError(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!recaptchaResponse) {
      setRecaptchaError(true);
      return;
    }

    setError(false);

    if (!selectedPub) {
      setPubError(true);
      return;
    }

    if (!price) {
      setPriceError(true);
      return;
    }

    if (!selectedBeerType) {
      setBeerTypeError(true);
      return;
    }

    

    try {
      const fullAddress = formatAddress(selectedPub); // Use the updated formatAddress function
      const address = encodeURIComponent(fullAddress);
      const mapboxResponse = await axios.get(
        `${MAPBOX_API_ENDPOINT}${address}.json`,
        {
          params: {
            access_token: MAPBOX_API_KEY,
          },
        }
      );

      if (mapboxResponse.status === 200 && mapboxResponse.data.features.length > 0) {
        const coordinates = mapboxResponse.data.features[0].center;

        const database = getDatabase();
        const pricesRef = ref(database, "beerPrices");
        const newPriceData = {
          beerType: selectedBeerType.value,
          price: parseFloat(price),
          pub: fullAddress,
          lat: coordinates[1],
          lng: coordinates[0],
          beerGarden,
          liveSports,
          liveMusic,
          happyHour,
          happyHourDays: happyHour ? happyHourDays : [], // Add happy hour days
          happyHourStart: happyHour ? happyHourStart : null, // Add happy hour start time
          happyHourEnd: happyHour ? happyHourEnd : null, // Add happy hour end time

          timestamp: new Date().toISOString(),
        };

        await push(pricesRef, newPriceData);

        setSelectedPub(null);
        setPrice("");
        setSelectedBeerType(null);
        setBeerGarden(false);
        setLiveSports(false);
        setLiveMusic(false);
        setError(false);
        setSubmitted(true);
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      } else {
        // console.log(`No coordinates found for ${selectedPub.label}`);
      }
    } catch (error) {
      // console.error("Error adding price:", error);
      setError(true);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white border rounded-lg p-4 sm:p-8 mb-8">
      <h2 className="text-xl mb-2 font-bold">Submit a pint!</h2>
      <p className="text-s sm:text-md mb-4 sm:mb-8 font-medium text-slate-700">
        This website relies on fellow pint drinkers (or anyone!) to contribute pint prices. Please be as accurate as possible, the more contributions we get, the more helpful we are!
      </p>
      <div className="mb-8">
        <label htmlFor="pub" className="block font-medium mb-1">
          Search for a Pub:
        </label>
        <PubsSelect selectedPub={selectedPub} onChange={handlePubChange} />
        {pubError && (
          <p className="text-red-500 text-sm mt-1">Please select a pub.</p>
        )}
      </div>
      <div className="mb-8">
        <label htmlFor="price" className="block font-medium mb-1">
          Enter the price of your pint:
        </label>
        <div className="relative">
          <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-700">
            £
          </span>
          <input
            type="number"
            inputMode="decimal"
            pattern="[0-9]*\.?[0-9]*"
            id="price"
            name="price"
            value={price}
            onChange={handlePriceChange}
            className={`w-full pl-8 pr-3 py-2 border rounded-md ${
              priceError ? "border-red-500" : "border-gray-300"
            }`}
          />
          {showPriceError && (
            <p className="text-red-500">
              Have you actually found a pint that's this price?{" "}
              <a
                className="underline"
                href="https://docs.google.com/forms/u/1/d/e/1FAIpQLSdyHWtxGXpyDhx6d8hSgHdk_eDNzHLLUJhJuF3-PDb7_SVy5Q/viewform?usp=sf_link"
                target="_blank"
                rel="noreferrer"
              >
                Give feedback
              </a>
            </p>
          )}
        </div>
        {priceError && (
          <p className="text-red-500 text-sm mt-1">Please enter a valid price between £1.50 and £14. if you've really found one that's cheaper or more expensive than this, then send me feedback below!</p>
        )}
      </div>
      <div className="mb-8">
        <label htmlFor="beerType" className="block font-medium mb-1">
          Select a beverage type:
        </label>
        <Select
          id="beerType"
          name="beerType"
          options={beerTypes}
          value={selectedBeerType}
          onChange={(selectedOption) => {
            setSelectedBeerType(selectedOption);
            setBeerTypeError(false);
          }}
          className="w-full"
          placeholder="Search for a beverage"
        />
        {beerTypeError && (
          <p className="text-red-500 text-sm mt-1">Please select a beverage.</p>
        )}
      </div>
      <div className="mb-8">
        <label className="block font-medium mb-1">Is there...</label>
        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            id="beerGarden"
            checked={beerGarden}
            onChange={(e) => setBeerGarden(e.target.checked)}
            className="mr-2 accent-green-600 w-5 h-5" // Increased size
          />
          <label htmlFor="beerGarden">🪴 Beer Garden?</label>
        </div>
        <div className="flex items-center mb-2">
          <input
            type="checkbox"
            id="liveSports"
            checked={liveSports}
            onChange={(e) => setLiveSports(e.target.checked)}
            className="mr-2 accent-green-600 w-5 h-5" // Increased size
          />
          <label htmlFor="liveSports">⚽️ Live Sports?</label>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="liveMusic"
            checked={liveMusic}
            onChange={(e) => setLiveMusic(e.target.checked)}
            className="mr-2 accent-green-600 w-5 h-5" // Increased size
          />
          <label htmlFor="liveMusic">🎶 Live Music?</label>
        </div>
      </div>
      <div className="flex items-center mb-8">
      <input
          type="checkbox"
          id="happyHour"
          checked={happyHour}
          onChange={(e) => setHappyHour(e.target.checked)}
          className="mr-2 accent-green-600 w-5 h-5"
        />
  <label htmlFor="happyHour">🍻 Was this pint on Happy Hour?</label>
</div>
{happyHour && ( // Show time inputs if happy hour is checked
        <div className="mb-8">
          <label className="block font-medium mb-1">What day(s) is happy hour? (select multiple)</label>
          <select
            multiple
            value={happyHourDays}
            onChange={(e) => {
              const options = Array.from(e.target.selectedOptions, option => option.value);
              setHappyHourDays(options);
            }}
            className="border rounded-md w-full p-2"
          >
            {daysOfWeek.map(day => (
              <option key={day.value} value={day.value}>
                {day.label}
              </option>
            ))}
          </select>
          <label className="block font-medium mb-1 mt-4">Happy hour start (24hr):</label>
          <div className="flex">
            <input
              type="time"
              value={happyHourStart}
              onChange={(e) => setHappyHourStart(e.target.value)}
              className="border rounded-md w-full p-2"
            />
            {/* <select
              value={happyHourStartPeriod}
              onChange={(e) => setHappyHourStartPeriod(e.target.value)}
              className="border rounded-md ml-2 p-2"
            >
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select> */}
          </div>
          <label className="block font-medium mb-1 mt-4">Happy hour end (24hr)</label>
          <div className="flex">
            <input
              type="time"
              value={happyHourEnd}
              onChange={(e) => setHappyHourEnd(e.target.value)}
              className="border rounded-md w-full p-2"
            />
            {/* <select
              value={happyHourEndPeriod}
              onChange={(e) => setHappyHourEndPeriod(e.target.value)}
              className="border rounded-md ml-2 p-2"
            >
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select> */}
          </div>
        </div>
      )}
      <ReCAPTCHA
        className="mb-4"
        sitekey="6LdXJPcnAAAAAERU_x3xpPuc3PLSE9tNxYjbSBei"
        onChange={handleRecaptchaChange}
      />
      {recaptchaError && (
        <p className="text-sm text-red-500 mt-2 mb-4">
          Please complete the CAPTCHA to submit the form.
        </p>
      )}
      <button
        type="submit"
        className="bg-rose-600 text-black-500 border hover:bg-slate-100 font-semibold py-2 px-4 mb-4 rounded-md"
      >
        Submit pint
      </button>
      {showMessage && (
        <p className="text-green-500 text-md mt-2">Pint contributed!✅</p>
      )}
    </form>
  );
};

export default BeerPriceForm;