// beerTypes.js
const beerTypes = [
    { value: "Guinness", label: "Guinness" },
    { value: "San Miguel", label: "San Miguel" },
    { value: "Magners", label: "Magners" },
    { value: "Stella Artois", label: "Stella Artois" },
    { value: "Stella Artois Unfiltered", label: "Stella Artois Unfiltered" },
    { value: "Peroni", label: "Peroni" },
    { value: "Heineken", label: "Heineken" },
    { value: "Corona", label: "Corona" },
    { value: "Kopparberg", label: "Kopparberg" },
    { value: "Strongbow", label: "Strongbow" },
    { value: "Bulmers", label: "Bulmers" },
    { value: "Budweiser", label: "Budweiser" },
    { value: "Old Speckled Hen", label: "Old Speckled Hen" },
    { value: "Thatchers", label: "Thatchers" },
    { value: "Camden Hells", label: "Camden Hells" },
    { value: "Camden Pale", label: "Camden Pale" },
    { value: "Carling", label: "Carling" },
    { value: "Strongbow Dark Fruits", label: "Strongbow Dark Fruits" },
    { value: "Amstel", label: "Amstel" },
    { value: "Carlsberg", label: "Carlsberg" },
    { value: "Tetley's Brewery", label: "Tetley's Brewery" },
    { value: "Grolsch", label: "Grolsch" },
    { value: "Kronenbourg 1664", label: "Kronenbourg 1664" },
    { value: "Marston's", label: "Marston's" },
    { value: "Hobgoblin", label: "Hobgoblin" },
    { value: "London Pride", label: "London Pride" },
    { value: "Tiger Beer", label: "Tiger Beer" },
    { value: "Estrella Damm", label: "Estrella Damm" },
    { value: "Beck's", label: "Beck's" },
    { value: "John Smith's", label: "John Smith's" },
    { value: "Birra Moretti", label: "Birra Moretti" },
    { value: "Murphy's", label: "Murphy's" },
    { value: "Woodpecker Cider", label: "Woodpecker Cider" },
    { value: "Coors Light", label: "Coors Light" },
    { value: "Corona Extra", label: "Corona Extra" },
    { value: "Old Mout", label: "Old Mout" },
    { value: "Foster's", label: "Foster's" },
    { value: "Sol", label: "Sol" },
    { value: "Boddingtons", label: "Boddingtons" },
    { value: "Marston's Pedigree", label: "Marston's Pedigree" },
    { value: "Desperados", label: "Desperados" },
    { value: "Red Stripe", label: "Red Stripe" },
    { value: "Newcastle Brown Ale", label: "Newcastle Brown Ale" },
    { value: "Doom Bar", label: "Doom Bar" },
    { value: "Leffe", label: "Leffe" },
    { value: "Old Peculier", label: "Old Peculier" },
    { value: "Chef & Brewer", label: "Chef & Brewer" },
    { value: "Greene King IPA", label: "Greene King IPA" },
    { value: "Shandy Bass", label: "Shandy Bass" },
    { value: "Black Sheep", label: "Black Sheep" },
    { value: "Carling Cider", label: "Carling Cider" },
    { value: "Beavertown - Neck Oil", label: "Beavertown - Neck Oil" },
    { value: "Beavertown - Gamma Ray", label: "Beavertown - Gamma Ray" },
    { value: "Rekorderlig", label: "Rekorderlig" },
    { value: "Fuller's", label: "Fuller's" },
    { value: "Bass", label: "Bass" },
    { value: "Banks Amber Bitter", label: "Banks Amber Bitter" },
    { value: "Adnams", label: "Adnams" },
    { value: "Old Crafty Hen", label: "Old Crafty Hen" },
    { value: "Kopparberg", label: "Kopparberg" },
    { value: "Efes", label: "Efes" },
    { value: "Skol", label: "Skol" },
    { value: "Hoegaarden", label: "Hoegaarden" },
    { value: "Thwaites", label: "Thwaites" },
    { value: "Worthington's", label: "Worthington's" },
    { value: "Adnams", label: "Adnams" },
    { value: "Bavaria", label: "Bavaria" },
    { value: "Abbot Ale", label: "Abbot Ale" },
    { value: "Bud Light", label: "Bud Light" },
    { value: "Erdinger", label: "Erdinger" },
    { value: "Brothers Cider", label: "Brothers Cider" },
    { value: "Bishops Finger", label: "Bishops Finger" },
    { value: "Henry Westons Vintage Cider", label: "Henry Westons Vintage Cider" },
    { value: "Tennent's", label: "Tennent's" },
    { value: "Merrydown", label: "Merrydown" },
    { value: "Asahi", label: "Asahi" },
    { value: "Miller", label: "Miller" },
    { value: "Somersby", label: "Somersby" },
    { value: "BrewDog Lost Lager", label: "BrewDog Lost Lager" },
    { value: "BrewDog Punk IPA", label: "BrewDog Punk IPA" },
    { value: "BrewDog Hazy Jane", label: "BrewDog Hazy Jane" },
    { value: "John Smiths", label: "John Smiths" },
    { value: "Marstons", label: "Marstons" },
    { value: "Cruzcampo", label: "Cruzcampo" },
    { value: "Mcewans", label: "Mcewans" },
    { value: "Whitstable Bay", label: "Whitstable Bay" },
    { value: "Cobra Indian", label: "Cobra Indian" },
    { value: "Staropramen", label: "Staropramen" },
    { value: "Erdinger", label: "Erdinger" },
    { value: "Aspall", label: "Aspall" },
    { value: "Newcastle Brown", label: "Newcastle Brown" },
    { value: "Madri", label: "Madri" },
    { value: "Desperados", label: "Desperados" },
    { value: "Abbott", label: "Abbott" },
    { value: "Oakham", label: "Oakham" },
    { value: "Black Sheep", label: "Black Sheep" },
    { value: "Rattler", label: "Rattler" },
    { value: "Sheppys", label: "Sheppys" },
    { value: "Woodforde", label: "Woodforde" },
    { value: "Warsteiner", label: "Warsteiner" },
    { value: "Holsten", label: "Holsten" },
    { value: "Inch's", label: "Inch's" },
    { value: "Brothers Cider", label: "Brothers Cider" },
    { value: "Staropramen", label: "Staropramen" },
    { value: "Old Golden Hen", label: "Old Golden Hen" },
    { value: "St Austell", label: "St Austell" },
    { value: "Singha", label: "Singha" },
    { value: "Henry Westons", label: "Henry Westons" },
    { value: "Savanna Dry Cider", label: "Savanna Dry Cider" },
    { value: "Oranjeboom", label: "Oranjeboom" },
    { value: "Pilsner Urquell", label: "Pilsner Urquell" },
    { value: "Tsingtao Beer", label: "Tsingtao Beer" },
    { value: "Caffrey's", label: "Caffrey's" },
    { value: "Blackthorn", label: "Blackthorn" },
    { value: "Spitfire Kentish Ale", label: "Spitfire Kentish Ale" },
    { value: "Bombardier", label: "Bombardier" },
    { value: "Wadworth", label: "Wadworth" },
    { value: "Westons", label: "Westons" },
    { value: "Harp", label: "Harp" },
    { value: "Badger Ales", label: "Badger Ales" },
    { value: "Tyskie", label: "Tyskie" },
    { value: "Special Brew", label: "Special Brew" },
    { value: "Duvel", label: "Duvel" },
    { value: "Tanglefoot", label: "Tanglefoot" },
    { value: "Brakspear Triple", label: "Brakspear Triple" },
    { value: "McEwan's", label: "McEwan's" },
    { value: "Wainwright", label: "Wainwright" },
    { value: "Courage Directors", label: "Courage Directors" },
    { value: "Shepherd Neame", label: "Shepherd Neame" },
    { value: "Fursty Ferret", label: "Fursty Ferret" },
    { value: "Brahma", label: "Brahma" },
    { value: "Coopers", label: "Coopers" },
    { value: "Hop House 13", label: "Hop House 13" },
    { value: "Ruddles County", label: "Ruddles County" },
    { value: "Budějovický Budvar", label: "Budějovický Budvar" },
    { value: "Budvar Original Lager", label: "Budvar Original Lager" },
    { value: "Brains", label: "Brains" },
    { value: "Innis & Gunn", label: "Innis & Gunn" },
    { value: "Wychwood", label: "Wychwood" },
    { value: "Samuel Adams", label: "Samuel Adams" },
    { value: "Chang", label: "Chang" },
    { value: "TsingTao", label: "TsingTao" },
    { value: "Otter Ale", label: "Otter Ale" },
    { value: "Bells", label: "Bells" },
    { value: "Cruzcampo", label: "Cruzcampo" },
    { value: "Tuborg", label: "Tuborg" },
    { value: "K Cider", label: "K Cider" },
    { value: "Lindemans", label: "Lindemans" },
    { value: "Chimay", label: "Chimay" },
    { value: "Goose Island", label: "Goose Island" },
    { value: "Frosty Jack", label: "Frosty Jack" },
    { value: "Gaymer", label: "Gaymer" },
    { value: "Miller Genunine Draft", label: "Miller Genunine Draft" },
    { value: "Warsteiner", label: "Warsteiner" },
    { value: "Bath Ales", label: "Bath Ales" },
    { value: "Old Rosie", label: "Old Rosie" },
    { value: "Blue Boar Lager", label: "Blue Boar Lager" },
    { value: "Hawkstone", label: "Hawkstone" },
    { value: "Soho Lager", label: "Soho Lager" },
    { value: "Deuchars", label: "Deuchars" },
    { value: "Prava", label: "Prava" },
      { value: "First Call", label: "First Call" },
      { value: "Rossi", label: "Rossi" },
      { value: "Rousse", label: "Rousse" },
      { value: "Albino Squid Assassin", label: "Albino Squid Assassin" },
      { value: "Witbier", label: "Witbier" },
      { value: "The Ghost", label: "The Ghost" },
      { value: "Cowcatcher APA", label: "Cowcatcher APA" },
      { value: "Alkoholfrei", label: "Alkoholfrei" },
      { value: "Ripper", label: "Ripper" },
      { value: "Dublin Porter", label: "Dublin Porter" },
      { value: "Ruby Rooster", label: "Ruby Rooster" },
      { value: "Stout", label: "Stout" },
      { value: "Draught Pale Ale", label: "Draught Pale Ale" },
      { value: "1664", label: "1664" },
      { value: "Nagori Yuki", label: "Nagori Yuki" },
      { value: "Psychedelic IPA", label: "Psychedelic IPA" },
      { value: "Notting Hill Blonde", label: "Notting Hill Blonde" },
      { value: "Herbal Ale", label: "Herbal Ale" },
      { value: "Mein Alkoholfrei", label: "Mein Alkoholfrei" },
      { value: "Green Tea IPA", label: "Green Tea IPA" },
      { value: "Copeland", label: "Copeland" },
      { value: "On the Cloud", label: "On the Cloud" },
      { value: "Daydream", label: "Daydream" },
      { value: "East End Mild", label: "East End Mild" },
      { value: "BoHo Bohemian Lager", label: "BoHo Bohemian Lager" },
      { value: "Santa Gose F It All", label: "Santa Gose F It All" },
      { value: "Fruli", label: "Fruli" },
      { value: "Double Brew Lager", label: "Double Brew Lager" },
      { value: "Premium Black", label: "Premium Black" },
      { value: "71 Lager", label: "71 Lager" },
      { value: "Fursty Ferret", label: "Fursty Ferret" },
      { value: "1555", label: "1555" },
      { value: "Electric India", label: "Electric India" },
      { value: "Nanny State", label: "Nanny State" },
      { value: "Elvis Juice", label: "Elvis Juice" },
      { value: "Cosmic Crush Cherry Sour", label: "Cosmic Crush Cherry Sour" },
      { value: "RIS", label: "RIS" },
      { value: "Soelaas", label: "Soelaas" },
      { value: "Watercress Warrior", label: "Watercress Warrior" },
      { value: "Golden Pride", label: "Golden Pride" },
      { value: "Pumpkin Party", label: "Pumpkin Party" },
      { value: "Midway IPA", label: "Midway IPA" },
      { value: "Remix IPA", label: "Remix IPA" },
      { value: "Golden Ale", label: "Golden Ale" },
      { value: "Best Bitter", label: "Best Bitter" },
      { value: "Nest White Ale", label: "Nest White Ale" },
      { value: "Red Ale", label: "Red Ale" },
      { value: "Dexter Milk Stout", label: "Dexter Milk Stout" },
      { value: "Mangolicious", label: "Mangolicious" },
      { value: "Dark Arts", label: "Dark Arts" },
      { value: "Merlin's Gold", label: "Merlin's Gold" },
      { value: "Stout", label: "Stout" },
      { value: "Workie Ticket", label: "Workie Ticket" },
      { value: "Sugura Bay Imperial IPA", label: "Sugura Bay Imperial IPA" },
      { value: "Kaga Roasted Tea Stout", label: "Kaga Roasted Tea Stout" },
      { value: "Beardo", label: "Beardo" },
      { value: "Liquid Mistress", label: "Liquid Mistress" },
      { value: "Splendid Tackle", label: "Splendid Tackle" },
      { value: "496", label: "496" },
      { value: "Jazzberry", label: "Jazzberry" },
      { value: "Bourgogne des Flandres", label: "Bourgogne des Flandres" },
      { value: "Iceberg", label: "Iceberg" },
      { value: "Tripel", label: "Tripel" },
      { value: "Master IPA", label: "Master IPA" },
      { value: "Waggledance", label: "Waggledance" },
      { value: "Black Wych", label: "Black Wych" },
      { value: "Orange Wedge", label: "Orange Wedge" },
      { value: "Golden Glory", label: "Golden Glory" },
      { value: "Gem", label: "Gem" },
      { value: "Barnsey", label: "Barnsey" },
      { value: "Chocs Away", label: "Chocs Away" },
      { value: "Puur", label: "Puur" },
      { value: "Himalayan Red Rye Ale", label: "Himalayan Red Rye Ale" },
      { value: "Black Cloak", label: "Black Cloak" },
      { value: "Belgian White Ale", label: "Belgian White Ale" },
      { value: "DDH IPA Number Two", label: "DDH IPA Number Two" },
      { value: "Punk IPA", label: "Punk IPA" },
      { value: "Vagabond", label: "Vagabond" },
      { value: "Radio Zombie Phone In", label: "Radio Zombie Phone In" },
      { value: "Brixton Atlantic Pale Ale", label: "Brixton Atlantic Pale Ale" },
      { value: "Brixton Coldharbour Lager", label: "Brixton Coldharbour Lager" },
      { value: "Brixton Electric West Coast", label: "Brixton Electric West Coast" },
      { value: "Brixton Low Voltage", label: "Brixton Low Voltage" },
      { value: "Brixton Reliance Pale Ale", label: "Brixton Reliance Pale Ale" },
      { value: "Beavertown - Bloody 'Ell", label: "Beavertown - Bloody 'Ell" },
      { value: "Beavertown - Lupuloid", label: "Beavertown - Lupuloid" },
      { value: "BrewDog - Dead Pony Club", label: "BrewDog - Dead Pony Club" },
      { value: "BrewDog - Wingman", label: "BrewDog - Wingman" },
      { value: "BrewDog - Vagabond", label: "BrewDog - Vagabond" },
      { value: "Fuller's - ESB", label: "Fuller's - ESB" },
      { value: "Fuller's - London Porter", label: "Fuller's - London Porter" },
      { value: "Tiny Rebel - Cwtch", label: "Tiny Rebel - Cwtch" },
      { value: "Tiny Rebel - Clwb Tropicana", label: "Tiny Rebel - Clwb Tropicana" },
      { value: "Goose Island - IPA", label: "Goose Island - IPA" },
      { value: "Thornbridge - Jaipur", label: "Thornbridge - Jaipur" },
      { value: "Thornbridge - Lukas", label: "Thornbridge - Lukas" },
      { value: "Cloudwater - IPA", label: "Cloudwater - IPA" },
      { value: "Sierra Nevada - Pale Ale", label: "Sierra Nevada - Pale Ale" },
      { value: "Sierra Nevada - Torpedo", label: "Sierra Nevada - Torpedo" },
      { value: "Lagunitas - IPA", label: "Lagunitas - IPA" },
      { value: "Samuel Smith's - Oatmeal Stout", label: "Samuel Smith's - Oatmeal Stout" },
      { value: "Samuel Smith's - Nut Brown Ale", label: "Samuel Smith's - Nut Brown Ale" },
      { value: "Theakston - Old Peculier", label: "Theakston - Old Peculier" },
      { value: "Theakston - Best Bitter", label: "Theakston - Best Bitter" },
      { value: "Young's - Special", label: "Young's - Special" },
      { value: "Young's - London Stout", label: "Young's - London Stout" },
      { value: "Five Points - Pale", label: "Five Points - Pale" },
      { value: "Five Points - XPA", label: "Five Points - XPA" },
      { value: "Adnams - Ghost Ship", label: "Adnams - Ghost Ship" },
      { value: "Harvey's - Sussex Best Bitter", label: "Harvey's - Sussex Best Bitter" },
      { value: "Bristol Beer Factory - Milk Stout", label: "Bristol Beer Factory - Milk Stout" },
      { value: "Moor Beer - Hoppiness", label: "Moor Beer - Hoppiness" },
      { value: "Moor Beer - Nor'Hop", label: "Moor Beer - Nor'Hop" },
        { value: "Dark Star - Hophead", label: "Dark Star - Hophead" },
        { value: "Dark Star - APA", label: "Dark Star - APA" },
        { value: "Oakham - Citra", label: "Oakham - Citra" },
        { value: "Oakham - Inferno", label: "Oakham - Inferno" },
        { value: "Redchurch - Brick Lane Lager", label: "Redchurch - Brick Lane Lager" },
        { value: "Redchurch - Shoreditch Blonde", label: "Redchurch - Shoreditch Blonde" },
        { value: "Magic Rock - High Wire", label: "Magic Rock - High Wire" },
        { value: "Magic Rock - Cannonball", label: "Magic Rock - Cannonball" },
        { value: "Wild Beer - Pogo", label: "Wild Beer - Pogo" },
        { value: "Wild Beer - Millionaire", label: "Wild Beer - Millionaire" },
        { value: "Purity - Mad Goose", label: "Purity - Mad Goose" },
        { value: "Purity - Pure UBU", label: "Purity - Pure UBU" },
        { value: "Buxton - Axe Edge", label: "Buxton - Axe Edge" },
        { value: "Buxton - Trolltunga", label: "Buxton - Trolltunga" },
        { value: "Northern Monk - Eternal", label: "Northern Monk - Eternal" },
        { value: "Northern Monk - New World IPA", label: "Northern Monk - New World IPA" },
        { value: "Meantime - London Pale Ale", label: "Meantime - London Pale Ale" },
        { value: "Meantime - London Lager", label: "Meantime - London Lager" },
        { value: "Camden - Gentleman’s Wit", label: "Camden - Gentleman’s Wit" },
        { value: "Camden - Ink", label: "Camden - Ink" },
        { value: "Fourpure - Juicebox", label: "Fourpure - Juicebox" },
        { value: "Fourpure - Easy Peeler", label: "Fourpure - Easy Peeler" },
        { value: "Kirkstall - Pale Ale", label: "Kirkstall - Pale Ale" },
        { value: "Kirkstall - Dissolution IPA", label: "Kirkstall - Dissolution IPA" },
        { value: "Saltaire - Blonde", label: "Saltaire - Blonde" },
        { value: "Saltaire - Triple Chocoholic", label: "Saltaire - Triple Chocoholic" },
        { value: "Titanic - Plum Porter", label: "Titanic - Plum Porter" },
        { value: "Titanic - Stout", label: "Titanic - Stout" },
        { value: "Rooster’s - Yankee", label: "Rooster’s - Yankee" },
        { value: "Rooster’s - Baby-Faced Assassin", label: "Rooster’s - Baby-Faced Assassin" },
        { value: "Black Sheep - Riggwelter", label: "Black Sheep - Riggwelter" },
        { value: "Black Sheep - Best Bitter", label: "Black Sheep - Best Bitter" },
          { value: "Harvey's Sussex Best Bitter", label: "Harvey's Sussex Best Bitter" },
          { value: "Thornbridge Jaipur", label: "Thornbridge Jaipur" },
          { value: "Thornbridge Kipling", label: "Thornbridge Kipling" },
          { value: "Fuller's London Porter", label: "Fuller's London Porter" },
          { value: "Fuller's ESB", label: "Fuller's ESB" },
          { value: "Theakston Old Peculier", label: "Theakston Old Peculier" },
          { value: "Theakston Best Bitter", label: "Theakston Best Bitter" },
          { value: "Greene King Abbot Ale", label: "Greene King Abbot Ale" },
          { value: "Greene King St Edmunds", label: "Greene King St Edmunds" },
          { value: "Samuel Smith's Oatmeal Stout", label: "Samuel Smith's Oatmeal Stout" },
          { value: "Samuel Smith's Taddy Porter", label: "Samuel Smith's Taddy Porter" },
          { value: "Marston's Old Empire", label: "Marston's Old Empire" },
          { value: "Marston's Saddle Tank", label: "Marston's Saddle Tank" },
          { value: "Badger Tanglefoot", label: "Badger Tanglefoot" },
          { value: "Badger The Fursty Ferret", label: "Badger The Fursty Ferret" },
          { value: "Badger Golden Champion", label: "Badger Golden Champion" },
          { value: "Timothy Taylor's Landlord", label: "Timothy Taylor's Landlord" },
          { value: "Timothy Taylor's Boltmaker", label: "Timothy Taylor's Boltmaker" },
          { value: "St Austell Tribute", label: "St Austell Tribute" },
          { value: "St Austell Proper Job", label: "St Austell Proper Job" },
          { value: "Robinsons Trooper", label: "Robinsons Trooper" },
          { value: "Robinsons Dizzy Blonde", label: "Robinsons Dizzy Blonde" },
          { value: "Adnams Broadside", label: "Adnams Broadside" },
          { value: "Adnams Ghost Ship", label: "Adnams Ghost Ship" },
          { value: "Wychwood Hobgoblin Gold", label: "Wychwood Hobgoblin Gold" },
          { value: "Wychwood Black Wych", label: "Wychwood Black Wych" },
          { value: "Wychwood King Goblin", label: "Wychwood King Goblin" },
          { value: "Williams Brothers - March of the Penguins", label: "Williams Brothers - March of the Penguins" },
          { value: "Williams Brothers - Joker IPA", label: "Williams Brothers - Joker IPA" },
          { value: "Williams Brothers - Birds and Bees", label: "Williams Brothers - Birds and Bees" },
          { value: "Brew York - Tonkoko", label: "Brew York - Tonkoko" },
          { value: "Brew York - Big Eagle", label: "Brew York - Big Eagle" },
          { value: "Brew York - Goose Willis", label: "Brew York - Goose Willis" },
          { value: "Hawkshead - Windermere Pale", label: "Hawkshead - Windermere Pale" },
          { value: "Hawkshead - Brodie's Prime", label: "Hawkshead - Brodie's Prime" },
          { value: "Hawkshead - Red", label: "Hawkshead - Red" },
          { value: "Tiny Rebel - Cwtch", label: "Tiny Rebel - Cwtch" },
          { value: "Tiny Rebel - Clwb Tropicana", label: "Tiny Rebel - Clwb Tropicana" },
          { value: "Tiny Rebel - Cali Pale", label: "Tiny Rebel" },
          { value: "Northern Monk - Eternal", label: "Northern Monk - Eternal" },
    { value: "Northern Monk - Faith", label: "Northern Monk - Faith" },
    { value: "Magic Rock - High Wire", label: "Magic Rock - High Wire" },
    { value: "Magic Rock - Cannonball", label: "Magic Rock - Cannonball" },
    { value: "Siren Craft Brew - Broken Dream", label: "Siren Craft Brew - Broken Dream" },
    { value: "Siren Craft Brew - Soundwave", label: "Siren Craft Brew - Soundwave" },
    { value: "The Kernel Brewery - Table Beer", label: "The Kernel Brewery - Table Beer" },
    { value: "The Kernel Brewery - Export India Porter", label: "The Kernel Brewery - Export India Porter" },
    { value: "Wild Beer Co - Millionaire", label: "Wild Beer Co - Millionaire" },
    { value: "Wild Beer Co - Pogo", label: "Wild Beer Co - Pogo" },
    { value: "Fourpure Brewing Co - Easy Peeler", label: "Fourpure Brewing Co - Easy Peeler" },
    { value: "Fourpure Brewing Co - Shapeshifter", label: "Fourpure Brewing Co - Shapeshifter" },
    { value: "Redchurch Brewery - Shoreditch Blonde", label: "Redchurch Brewery - Shoreditch Blonde" },
    { value: "Redchurch Brewery - Bethnal Pale Ale", label: "Redchurch Brewery - Bethnal Pale Ale" },
    { value: "Pressure Drop Brewing - Pale Fire", label: "Pressure Drop Brewing - Pale Fire" },
    { value: "Pressure Drop Brewing - Stokey Brown", label: "Pressure Drop Brewing - Stokey Brown" },
    { value: "Brew By Numbers - 55 Double IPA", label: "Brew By Numbers - 55 Double IPA" },
    { value: "Brew By Numbers - 01 Saison", label: "Brew By Numbers - 01 Saison" },
    { value: "Wiper and True - Milk Shake", label: "Wiper and True - Milk Shake" },
    { value: "Wiper and True - Kaleidoscope", label: "Wiper and True - Kaleidoscope" },
    { value: "Burning Sky Brewery - Arise", label: "Burning Sky Brewery - Arise" },
    { value: "Burning Sky Brewery - Saison à la Provision", label: "Burning Sky Brewery - Saison à la Provision" },
    { value: "Mondo Brewing Company - Dennis Hopp’r", label: "Mondo Brewing Company - Dennis Hopp’r" },
    { value: "Mondo Brewing Company - Kemosabe", label: "Mondo Brewing Company - Kemosabe" },
    { value: "Five Points Brewing Company - Railway Porter", label: "Five Points Brewing Company - Railway Porter" },
    { value: "Five Points Brewing Company - Pale", label: "Five Points Brewing Company - Pale" },
    { value: "Cloudwater Brew Co - IPA", label: "Cloudwater Brew Co - IPA" },
    { value: "Cloudwater Brew Co - Pale Ale", label: "Cloudwater Brew Co - Pale Ale" },
    { value: "Deya Brewing Company - Steady Rolling Man", label: "Deya Brewing Company - Steady Rolling Man" },
    { value: "Deya Brewing Company - Into the Haze", label: "Deya Brewing Company - Into the Haze" },
    { value: "Canopy Session IPA", label: "Canopy Session IPA" },
    { value: "Hoptimistic", label: "Hoptimistic" },
    { value: "Helles Lager", label: "Helles Lager" },
    { value: "Super Sharp Shooter", label: "Super Sharp Shooter" },
    { value: "Keller Pils", label: "Keller Pils" },
    { value: "Wild Cat Cider", label: "Wild Cat Cider" },
    { value: "Saison D'Avon", label: "Saison D'Avon" },
    { value: "Yawn Pale Ale", label: "Yawn Pale Ale" },
    { value: "God's Twisted Sister Stout", label: "God's Twisted Sister Stout" },
    { value: "Monster from Munich", label: "Monster from Munich" },
    { value: "House Lager", label: "House Lager" },
    { value: "House Ale", label: "House Ale" },
    { value: "Beavertown Bones", label: "Beavertown Bones" },
    { value: "Mahou", label: "Mahou" },
    { value: "TLP Dry Hops Ale", label: "TLP Dry Hops Ale" },
    { value: "Beavertown - Lazer Crush", label: "Beavertown - Lazer Crush" },
    { value: "Porterhouse Yippy IPA", label: "Porterhouse Yippy IPA" },
    { value: "Porterhouse Nitro Red Ale", label: "Porterhouse Nitro Red Ale" },
    { value: "Porterhouse Plain Porter", label: "Porterhouse Plain Porter" },
    { value: "Porterhouse Oyster Stout", label: "Porterhouse Oyster Stout" },
    { value: "Sambrooks Session Pale Ale", label: "Sambrooks Session Pale Ale" },
    { value: "Stone Neverending Haze IPA", label: "Stone Neverending Haze IPA" },

    









      
    
    

  
    




    // Add more beer types as needed
  ];
  
  export default beerTypes;