import React from 'react';
import ReactDOM from 'react-dom';
import CheapestPintMap from './CheapestPintMap';
import Navbar from './Navbar';

const Map = ({ onClose }) => {
  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
      <div className="relative bg-white w-full h-full max-w-full max-h-full p-0">
      <CheapestPintMap />
      </div>
    </div>,
    document.body // Render modal into the body element
  );
};

export default Map;
