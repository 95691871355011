import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLoadScript, LoadScript, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { MarkerClusterer } from '@react-google-maps/api';
import { getDatabase, ref, onValue, off } from "firebase/database";
import { initializeApp } from "firebase/app";
import { formatDistanceToNow } from "date-fns";
import './CustomInfoWindow.css';
import MapLegend from './mapLegend';
import greenSvgIcon from './assets/green-icon.svg';
import orangeSvgIcon from './assets/orange-icon.svg';
import redSvgIcon from './assets/red-icon.svg';
import blueDotSvgIcon from './assets/blue-dot.svg';
import greenStarSvgIcon from './assets/green-icon-star.svg';
import orangeStarSvgIcon from './assets/orange-icon-star.svg';
import redStarSvgIcon from './assets/red-icon-star.svg';
import { Link } from 'react-router-dom';
import greenClusterIcon from './assets/green-cluster.svg';
import orangeClusterIcon from './assets/orange-cluster.svg';
import redClusterIcon from './assets/red-cluster.svg';


const libraries = ["places"];


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "average-pint-london.firebaseapp.com",
  databaseURL: "https://average-pint-london-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "average-pint-london",
  storageBucket: "average-pint-london.appspot.com",
  messagingSenderId: "284009350282",
  appId: "1:284009350282:web:ceec7375a8a98f60e81973",
  measurementId: "G-3DE63Z7MQE"
};

const containerStyle = {
  width: '100%',
  height: '900px',
};

const center = {
  lat: 51.50,
  lng: -0.1,
};

const formatPrice = (price) => price.toFixed(2);

const CustomInfoWindow = ({ pub, count }) => {
  const otherPints = pub.pints.filter(pint => pint.price !== pub.price || pint.timestamp !== pub.timestamp);

  return (
    <div className="custom-info-window">
      <Link to={`/pub/${pub.id}`} className="text-xl text-green-600 underline font-bold mb-4">{pub.pub}</Link>
      <p className="mt-4 font-normal text-slate-500">Cheapest Pint:</p>
      <p className="text-xl font-bold">
        {pub.beerType} | £{formatPrice(pub.price)}
      </p>
      <p className="font-medium text-slate-500">
        Last Recorded: {formatDistanceToNow(new Date(pub.timestamp))} ago
      </p>
      <p className="font-medium text-slate-500">
        Contributions: {count}
      </p>
      <div className="other-pints mt-4 text-slate-700">
        <p className="text-md font-bold mb-1">Other Pints:</p>
        {otherPints.length === 0 ? (
          <p className='text-sm text-slate-500 font-bold'>None</p> // Display "None" if no other pints
        ) : (
          otherPints.map((pint, index) => (
            <div key={index}>
              <p className='text-sm'>{pint.beerType} · <strong>£{pint.price}</strong> - {formatDistanceToNow(new Date(pint.timestamp))} ago</p>
            </div>
          ))
        )}
      </div>
      <p className="text-md font-bold mb-2 mt-4">Other ammenities:</p>
      <p className="font-medium text-slate-500">
        🍺 Beer Garden: {pub.beerGarden ? 'Yes' : 'Not reported '}
      </p>
      <p className="font-medium text-slate-500">
        🎶 Live Music: {pub.liveMusic ? 'Yes' : 'Not reported'}
      </p>
      <p className="font-medium text-slate-500">
        ⚽️ Live Sports: {pub.liveSports ? 'Yes' : 'Not reported'}
      </p>
      <p className='font-medium text-slate-500'>🍻 Happy Hour: <b className={pub.happyHour ? 'text-green-600' : 'text-slate-500'}>{pub.happyHour ? 'Yes' : 'Not reported'}</b></p>

      <Link to={`/pub/${pub.id}`} className="text-green-600 text-lg text-bold underline mt-4 font-semibold block">Pub details →</Link>
    </div>
  );
};

const CheapestPintMap = () => {
  const [excludeWetherspoons, setExcludeWetherspoons] = useState(false);
  const [pubs, setPubs] = useState([]);
  const [isOpen, setIsOpen] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState(center);
  const [mapZoom, setMapZoom] = useState(13);
  const [currentZoom, setCurrentZoom] = useState(11);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const mapRef = useRef(null);

  const handleExcludeWetherspoonsToggle = () => {
    setExcludeWetherspoons(!excludeWetherspoons);
  };

  

  useEffect(() => {
    const firebaseApp = initializeApp(firebaseConfig);
    const database = getDatabase(firebaseApp);
    const pubsRef = ref(database, "beerPrices");

    const onDataChange = (snapshot) => {
      const data = snapshot.val();
      console.log("Firebase Data:", data);
      const pubData = data ? Object.values(data) : [];
      const pubMap = {};

      pubData.forEach((pub) => {
        if (!pubMap[pub.pub]) {
          pubMap[pub.pub] = {
            ...pub,
            pints: [],
            id: pub.pub.replace(/\s+/g, '-').toLowerCase(),
          };
        }
        pubMap[pub.pub].pints.push({
          beerType: pub.beerType,
          price: pub.price,
          timestamp: pub.timestamp,
        });
      });

      const getUserLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              setUserLocation({ lat: latitude, lng: longitude });
              setMapCenter({ lat: latitude, lng: longitude }); // Center map on user location
              setMapZoom(17); // Zoom in on user location
            },
            (error) => {
              console.error("Error getting user location:", error);
            }
          );
        }
      };
    
      getUserLocation();

      const updatedPubs = Object.values(pubMap).map((pub) => ({
        ...pub,
        lat: parseFloat(pub.lat),
        lng: parseFloat(pub.lng),
        cheapestPint: pub.price,
        lastRecorded: pub.timestamp,
        beerGarden: pub.beerGarden,
        liveSports: pub.liveSports,
        liveMusic: pub.liveMusic,
      }));

      console.log("Updated Pubs:", updatedPubs);
      setPubs(updatedPubs);
    };

    onValue(pubsRef, onDataChange);

    return () => {
      off(pubsRef, onDataChange);
    };
  }, []);



  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchInput)}.json?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`
    );
    const data = await response.json();
    const results = data.features.map(feature => ({
      placeName: feature.place_name,
      lat: feature.geometry.coordinates[1],
      lng: feature.geometry.coordinates[0]
    }));
    setSearchResults(results);

    if (results.length > 0) {
      setMapCenter({
        lat: results[0].lat,
        lng: results[0].lng
      });
      setMapZoom(14);
    }
  };

  const handleZoomChanged = () => {
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom();
      setCurrentZoom(newZoom);
      console.log("Current zoom level:", newZoom);
    }
  };

  const onLoad = useCallback((map) => {
    mapRef.current = map;
    setMapLoaded(true);
    setGoogleMapsLoaded(true);
    console.log("Map loaded");
  }, []);

  const createClusterIcon = useCallback((cluster) => {
    const markers = cluster.getMarkers();
    let greenCount = 0;
    let orangeCount = 0;
    let redCount = 0;
  
    markers.forEach((marker) => {
      const price = marker.getIcon().url.includes('green') ? 5 : 
                    marker.getIcon().url.includes('orange') ? 6.5 : 7.5;
      if (price < 6) greenCount++;
      else if (price >= 6 && price < 7) orangeCount++;
      else redCount++;
    });
  
    const total = greenCount + orangeCount + redCount;
    const size = Math.min(total, 40) + 30;
  
    // Determine the majority color
    let majorityColor;
    if (greenCount >= orangeCount && greenCount >= redCount) {
      majorityColor = '#4CAF50';
    } else if (orangeCount >= greenCount && orangeCount >= redCount) {
      majorityColor = '#4CAF50';
    } else {
      majorityColor = '#4CAF50';
    }
  
    return {
      text: total.toString(),
      textColor: "#ffffff",
      textSize: 14,
      fillColor: majorityColor,
      strokeColor: "#ffffff",
      strokeWeight: 2,
      scale: size / 60
    };
  }, []);

  const { isLoaded } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      libraries: libraries,
    });
  
    if (!isLoaded) return <div>Loading...</div>;
  


  return (
      <div className='relative'>
        <div className="flex flex-col p-4 md:flex-row items-center md:justify-between w-full md:w-auto space-y-4 md:space-y-0">
          <div className="flex flex-col md:flex-row md:space-x-2 w-full md:w-auto">
            <input
              type="text"
              placeholder="Drinking somewhere?"
              value={searchInput}
              onKeyDown={handleKeyDown}
              onChange={handleSearchInputChange}
              className="p-2 rounded border border-gray-300 shadow-sm w-full md:w-auto"
              style={{ maxWidth: '600px' }}
            />
            <button
              onClick={handleSearch}
              className="p-3 bg-green-600 text-white text-xs font-bold rounded mt-2 md:mt-0 w-full md:w-auto"
            >
              Search
            </button>
          </div>

          {/* <label className="flex cursor-pointer space-x-3 w-full md:w-auto">
            <div className="relative">
              <input
                type="checkbox"
                id="toggle"
                checked={excludeWetherspoons}
                onChange={handleExcludeWetherspoonsToggle}
                className="items-center sr-only"
              />
              <div
                className={`block w-14 h-8 rounded-full shadow-inner transition-colors duration-300 ${
                  excludeWetherspoons ? 'bg-green-600' : 'bg-gray-300'
                }`}
              ></div>
              <div
                className={`absolute top-1 left-1 bg-white w-6 h-6 items-center rounded-full shadow transform transition-transform duration-300 ${
                  excludeWetherspoons ? 'translate-x-6 bg-green-600' : 'bg-gray-500'
                }`}
              ></div>
            </div>
            <span className="text-gray-700 font-medium">Exclude Wetherspoons</span>
          </label> */}
        </div>

        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={mapZoom}
          onLoad={onLoad}
          onZoomChanged={handleZoomChanged}
          options={{
            styles: [
              {
                fullscreenControl: true,
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
            ],
            streetViewControl: false,
            fullscreenControl: true,
            enableLocationButton: true,
            gestureHandling: "greedy",
          }}
        >
          {userLocation && (
            <Marker
              position={userLocation}
              icon={{
                url: blueDotSvgIcon,
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            />
          )}

          <MapLegend />

          {mapLoaded && googleMapsLoaded && pubs.length > 0 && (
            <MarkerClusterer
  options={{
    maxZoom: 12,
    minimumClusterSize: 1,
    zoomOnClick: true,
    averageCenter: true,
    gridSize: 60,
    calculator: (markers, numStyles) => {
      const count = markers.length;
      const averagePrice = markers.reduce((sum, marker) => {
        const price = marker.getIcon().url.includes('green') ? 5 : 
                      marker.getIcon().url.includes('orange') ? 6.5 : 7.5;
        return sum + price;
      }, 0) / count;

      let clusterColor;
      if (averagePrice < 6) {
        clusterColor = '#4CAF50'; // Green
      } else if (averagePrice >= 6 && averagePrice < 7) {
        clusterColor = '#FFA500'; // Orange
      } else {
        clusterColor = '#FF0000'; // Red
      }

      return {
        text: count.toString(),
        index: 0,
        title: `Cluster of ${count} pubs`,
        iconOptions: {
          text: count.toString(),
          textColor: "#ffffff",
          textSize: 14,
          fillColor: clusterColor, // Set the cluster color based on average price
          strokeColor: "#ffffff",
          strokeWeight: 2,
          scale: Math.min(count, 40) + 30 / 60
        }
      };
    },
    clusterClass: 'custom-clustericon',
    renderer: {
      render: function ({ count, position }, stats) {
        const color = this.palette(count / stats.clusters.markers.max);
        const svg = window.btoa(`
          <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
            <circle cx="120" cy="120" opacity=".8" r="70" />    
          </svg>`);
        return new window.google.maps.Marker({
          position,
          icon: {
            url: `data:image/svg+xml;base64,${svg}`,
            scaledSize: new window.google.maps.Size(75, 75),
          },
          label: {
            text: String(count),
            color: "rgba(255,255,255,0.9)",
            fontSize: "12px",
          },
          zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
        });
      }
    }
  }}
>

{(clusterer) =>
    pubs
      .filter(pub => !excludeWetherspoons || !pub.pub.toLowerCase().includes('wetherspoon'))
      .map((pub, index) => {
                    // console.log("Rendering marker for pub:", pub.pub, "at", pub.lat, pub.lng);
                    const key = `${pub.pub}_${pub.beerType}`;
                    const count = pub.pints.length;
                    let markerIconUrl;

                    if (count >= 3) {
                      if (pub.price < 6) {
                        markerIconUrl = greenStarSvgIcon;
                      } else if (pub.price >= 6 && pub.price < 7) {
                        markerIconUrl = orangeStarSvgIcon;
                      } else {
                        markerIconUrl = redStarSvgIcon;
                      }
                    } else {
                      if (pub.price < 6) {
                        markerIconUrl = greenSvgIcon;
                      } else if (pub.price >= 6 && pub.price < 7) {
                        markerIconUrl = orangeSvgIcon;
                      } else {
                        markerIconUrl = redSvgIcon;
                      }
                    }

                    return (
                      <Marker
                        key={key}
                        position={{ lat: pub.lat, lng: pub.lng }}
                        icon={{
                          url: markerIconUrl,
                          scaledSize: new window.google.maps.Size(48, 56),
                        }}
                        onClick={() => setIsOpen(index)}
                        clusterer={clusterer}
                        visible={currentZoom > 10}
                        options={{
                          pub: pub
                        }}
                      >
                        {isOpen === index && (
                          <InfoWindow onCloseClick={() => setIsOpen(null)}>
                            <CustomInfoWindow pub={pub} count={count} />
                          </InfoWindow>
                        )}
                      </Marker>
                    );
                  })}
                              </MarkerClusterer>
                            )}
                          </GoogleMap>
                        </div>
                    );
                  };
                  
                  export default CheapestPintMap;