import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { formatDistanceToNow } from "date-fns";



// Replace with your Firebase config from the Firebase console
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "average-pint-london.firebaseapp.com",
    databaseURL: "https://average-pint-london-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "average-pint-london",
    storageBucket: "average-pint-london.appspot.com",
    messagingSenderId: "284009350282",
    appId: "1:284009350282:web:ceec7375a8a98f60e81973",
    measurementId: "G-3DE63Z7MQE"
  };

  // Check if Firebase is not already initialized
if (!firebase.apps.length) {
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
  }

  const CheapestPintSection = () => {
    const [cheapestPint, setCheapestPint] = useState(null);
    const [pintCount, setPintCount] = useState(0);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchCheapestPint = async () => {
        try {
          const snapshot = await firebase
            .database()
            .ref("beerPrices")
            .orderByChild("price")
            .limitToFirst(1)
            .once("value");
      
          const cheapestPintData = snapshot.val();
          if (cheapestPintData) {
            const cheapestPint = Object.values(cheapestPintData)[0];
            setCheapestPint(cheapestPint);
            fetchPintCount(cheapestPint.beerType, cheapestPint.pub); // Call fetchPintCount
          }
          setLoading(false);
        } catch (error) {
          console.error("Error fetching pint data:", error);
          setLoading(false);
        }
      };
      
  
          const fetchPintCount = async (beerType, pub) => {
            try {
              const beerTypePubKey = `${beerType}_${pub}`;
              const snapshot = await firebase
                .database()
                .ref("beerPrices")
                .orderByChild("beerType_pub")
                .equalTo(beerTypePubKey)
                .once("value");
              setPintCount(snapshot.numChildren());
            } catch (error) {
              console.error("Error fetching pint count:", error);
              setPintCount(0); // Set pintCount to 0 if there's an error
            }
          };
  
      fetchCheapestPint();
    }, []);
  
    const formatTimestamp = (timestamp) => {
      const date = new Date(timestamp);
      return formatDistanceToNow(date, { addSuffix: true });
    };
    
  
    return (
      <div className="w-full mb-2 sm:mb-4 p-4 sm:p-8 bg-slate-50 rounded border">
        <h2 className="text-base sm:text-2xl font-bold mb-2 sm:mb-4">Cheapest pint in the UK is currently</h2>
        {loading ? (
          <p>Loading...</p>
        ) : cheapestPint ? (
          <div>
            <p className="text-l text-gray-600">{cheapestPint.beerType}</p>
            <p className="font-extrabold text-4xl">£{cheapestPint.price.toFixed(2)}</p>
            <a
  className="text-blue-900 underline"
  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(cheapestPint.pub)}`}
  target="_blank"
  rel="noreferrer"
>
  <p className="mb-4">{cheapestPint.pub}</p>
</a>            {/* <p className="text-gray-500 text-sm">Recorded {pintCount} times</p> */}
            <p className="text-gray-500 text-sm">Last recorded: {formatTimestamp(cheapestPint.timestamp)}</p>
          </div>
        ) : (
          <p>No cheapest pint available</p>
        )}
      </div>
    );
  };

  
  export default CheapestPintSection;