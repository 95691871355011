// src/components/Navbar.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import UtilityNavbar from './UtilityNavbar';
import logo from './CheapestPint.svg';

const Navbar = () => {
  return (
    <>
    {/* <UtilityNavbar /> */}
    <div class="bg-green-100 text-center p-2 lg:px-4">    
      <span class="font-semibold text-sm mr-2 text-left text-green-700 flex-auto">A pint with a ⭐️ has been reported at the same price, 3 or more times</span>
    </div>
    <nav className="container mx-auto max-w-6xl p-4 sm:px-4 sm:py-8 bg-white">
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center">
        <div className="flex items-center pt-2 pb-6 sm:pb-0"> {/* Added flex container for logo and title */}
        <Link to="/">
          <img src={logo} alt="Cheapest pint logo " className="h-12 mr-2" /> {/* SVG logo */}
          </Link>
          <div className="text-grey-900 text-2xl font-bold mb-4 sm:mb-2">
          </div>
        </div>
        <div className="w-full sm:w-auto">
          <a 
            href="#beerForm" 
            className="block w-full sm:inline-flex sm:w-auto items-center px-6 py-4 bg-green-100 rounded-lg text-green-700 font-semibold text-sm text-center hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-green-300 transition-all duration-300" 
            aria-label="Submit a pint"
          >
            Submit a pint 🍺
          </a>
        </div>
      </div>
    </nav>
    </>

    
  );
};

export default Navbar;