import React, { useEffect, useState } from "react";
import Select from "react-select";
import beerTypes from "./beerTypes";
import "./output.css";
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, push, off } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import BeerPriceForm from "./BeerPriceForm";
import PricesTable from "./PricesTable";
import CheapestPintSection from "./CheapestPintSection";
import CheapestPintSelect from "./CheapestPintSelect";
import CheapestPintMap from "./CheapestPintMap";
import FAQs from "./faqs";
import CookieConsent from "react-cookie-consent";
import PrivacyPolicy from './PrivacyPolicy';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Navbar from "./Navbar";
import Map from "./MapModal";
import CookieModal from "./CookieModal";
import { useCookieConsent } from './utils/cookieManagement';
import PubPage from './PubPage';

// Replace with your Firebase config from the Firebase console
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "average-pint-london.firebaseapp.com",
  databaseURL: "https://average-pint-london-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "average-pint-london",
  storageBucket: "average-pint-london.appspot.com",
  messagingSenderId: "284009350282",
  appId: "1:284009350282:web:ceec7375a8a98f60e81973",
  measurementId: "G-3DE63Z7MQE"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const database = getDatabase(firebaseApp);
const pricesRef = ref(database, "beerPrices");


// Define the function to calculate average price for a specific beer type
const calculateAveragePriceForBeerType = (beerType, prices) => {
  const filteredPrices = prices.filter((price) => price.beerType === beerType);
  if (filteredPrices.length === 0) return 0;

  const total = filteredPrices.reduce((sum, price) => sum + price.price, 0);
  return (total / filteredPrices.length).toFixed(2);
};

const App = () => {
  const [selectedBeerType, setSelectedBeerType] = useState(null);
  const [selectedAverageBeerType, setSelectedAverageBeerType] = useState(null);
  const [averagePriceForBeerType, setAveragePriceForBeerType] = useState(0);
  const [prices, setPrices] = useState([]);
  const [pubs, setPubs] = useState([]); // Add state for pubs
  const [activeTab, setActiveTab] = useState("map"); // Add state for active tab


  const { isConsentGiven, isAllCookiesAccepted } = useCookieConsent();

  useEffect(() => {
    const onDataChange = (snapshot) => {
      const data = snapshot.val();
      const priceData = data ? Object.values(data) : [];
      setPrices(priceData);

      // Process pubs data
      const pubMap = {};
      priceData.forEach((price) => {
        if (!pubMap[price.pub]) {
          pubMap[price.pub] = {
            ...price,
            pints: [],
            id: price.pub.replace(/\s+/g, '-').toLowerCase(), // Generate a unique ID for each pub
          };
        }
        pubMap[price.pub].pints.push({
          beerType: price.beerType,
          price: price.price,
          timestamp: price.timestamp,
        });
      });

      const updatedPubs = Object.values(pubMap).map((pub) => ({
        ...pub,
        lat: parseFloat(pub.lat),
        lng: parseFloat(pub.lng),
        cheapestPint: pub.price,
        lastRecorded: pub.timestamp,
        beerGarden: pub.beerGarden,
        liveSports: pub.liveSports,
        liveMusic: pub.liveMusic,
      }));

      setPubs(updatedPubs);
    };

    onValue(pricesRef, onDataChange);

    return () => {
      off(pricesRef, onDataChange);
    };
  }, []);

  const handleAddPrice = (newPrice) => {
    const timestamp = new Date().toISOString();
    push(ref(database, "beerPrices"), { ...newPrice, timestamp })
      .then(() => {
        // console.log("Price added successfully.");
      })
      .catch((error) => {
        // console.error("Error adding price:", error);
      });
  };

  const calculateAveragePrice = () => {
    if (prices.length === 0) return 0;

    const total = prices.reduce((sum, price) => sum + price.price, 0);
    return (total / prices.length).toFixed(2);
  };

  const handleBeerTypeChange = (selectedOption) => {
    setSelectedBeerType(selectedOption);
    setAveragePriceForBeerType(calculateAveragePriceForBeerType(selectedOption?.value, prices));
  };

  const handleAverageBeerTypeChange = (selectedOption) => {
    setSelectedAverageBeerType(selectedOption);
    const averagePrice = calculateAveragePriceForBeerType(selectedOption?.value, prices);
    setAveragePriceForBeerType(averagePrice !== 0 ? averagePrice : "");
  };

  return (
    <Router>
      <CookieModal />
      <Navbar />
      <div className="bg-green-50 min-h-screen justify-center p-0 sm:p-4">
        <div className="container mx-auto max-w-6xl p-4 sm:px-6 mb-8 sm:py-8 bg-white rounded-lg">
          <Routes>
            <Route path="/" element={
              <>
                <header className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-10 mb-8">
                  <div className="flex-1">
                    <h1 className="text-2xl pb-2 sm:text-5xl sm: te mb-4 sm:mb-2 font-black text-black-700 max-w-prose">
                      A crowdsourced guide to London and the UK's cheapest pint prices.
                    </h1>
                    <p className="max-w-prose text-slate-700"> Explore the cheapest pint prices across pubs in London and the UK. See their locations and other ammenities like beer gardens, live music and live sport. Contribute your own pint prices too. We even went viral on reddit. </p>
                  </div>
                  
                  <div className="flex flex-row gap-4 mt-4 sm:mt-0 hidden sm:block">
                  <CheapestPintSection />

                  </div>
                </header>
                
<ul className="flex flex-wrap -mb-px border-b border-gray-200">
              <li className="me-2 text-bold">
                <a 
                  onClick={() => setActiveTab("map")} 
                  className={`inline-block p-4 border-b-2 rounded-t-lg text-black ${activeTab === "map" ? "text-black text-green-600 border-green-600" : "text-black border-transparent hover:text-gray-600 hover:border-gray-300"}`}
                >
                  Pint Map
                </a>
              </li>
              <li className="me-2">
                <a 
                  onClick={() => setActiveTab("prices")} 
                  className={`inline-block p-4 border-b-2 rounded-t-lg text-black ${activeTab === "prices" ? "text-black text-green-600 border-green-600" : "text-black border-transparent hover:text-gray-600 hover:border-gray-300"}`}
                >
                  Pint list
                </a>
              </li>
            </ul>

            <section id="cheapestSection" className="w-full mb-6">
            {activeTab === "map" ? <CheapestPintMap /> : <PricesTable prices={prices} />}
          </section>
                {/* <section id="beerForm" className="grid grid-cols-1 gap-2 sm:gap-4 sm:grid-cols-2"> */}
                  <div id="beerForm">
                  <BeerPriceForm onAddPrice={handleAddPrice} />
                  </div>
                  {/* <div className="w-full">
                    <h2 className="text-base sm:text-2xl font-bold"></h2>
                    <div className="max-h-dvh mb-8 overflow-y-auto">
                      <PricesTable prices={prices} />
                    </div>
                  </div>
                </section> */}
                <div className="text-xl font-bold mb-4">
                  <h3>
                    Other stats:
                  </h3>

                </div>
                <section className="grid grid-cols-1 gap-2 sm:gap-4 sm:grid-cols-2">
                  {/* <div id="cheapestSection">
                  </div> */}
                  <CheapestPintSelect
                    firebaseConfig={firebaseConfig}
                    selectedBeerType={selectedBeerType}
                    setSelectedBeerType={setSelectedBeerType}
                  />
                  <div className="w-full mb-2 sm:mb-4 p-4 sm:p-8 bg-slate-50 rounded border">
                    <h2 className="text-base sm:text-2xl font-bold mb-2 sm:mb-4">Average price of a pint in the UK is currently</h2>
                    <p className="text-4xl sm:text-4xl font-extrabold">£{calculateAveragePrice()}</p>
                  </div>
                  
                  <div className="rounded-lg border bg-slate-50 border-gray-200 p-4 sm:p-6 w-full mb-4 sm:mb-4">
                    <h2 className="text-base sm:text-2xl font-bold mb-4 sm:mb-4">Average price of a</h2>
                    <Select
                      id="averageBeerType"
                      name="averageBeerType"
                      options={beerTypes}
                      value={selectedAverageBeerType}
                      onChange={handleAverageBeerTypeChange}
                      className="w-full mr-2 sm:mr-4 mb-4 sm:mb-4"
                      placeholder="Select a beverage"
                      aria-label="Select beer type for average price"
                    />
                    <div className="w-full">
                      {averagePriceForBeerType ? (
                        <>
                          <p className="text-xs sm:text-s font-m">is currently</p>
                          <p className="text-4xl sm:text-4xl font-extrabold">£{averagePriceForBeerType}</p>
                        </>
                      ) : (
                        <p className="text-xs sm:text-s font-m"></p>
                      )}
                    </div>
                  </div>
                </section>
              </>
            } />
            
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/map" element={<Map />} />
            <Route path="/pub/:pubId" element={<PubPage pubs={pubs} />} />
          </Routes>
        </div>
        <div className="container mx-auto max-w-6xl p-2 sm:px-4 mb-8 sm:py-8 bg-white rounded-lg">
        <FAQs />

          <a href="https://www.buymeacoffee.com/averagepint" target="_blank" className="block text-left text-blue-900 underline hover:underline hover:text-blue-500" aria-label="Buy me a beer">
            <span className="text-2xl" role="img" aria-label="Buy me a beer">🍻</span> Buy me a beer
          </a>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdyHWtxGXpyDhx6d8hSgHdk_eDNzHLLUJhJuF3-PDb7_SVy5Q/viewform?usp=sf_link" target="_blank" className="block text-left mt-4 text-blue-900 underline hover:underline hover:text-blue-500" aria-label="Give feedback">
            <span className="text-2xl" role="img" aria-label="Give Feedback">🗣️</span> Give feedback
          </a>
          <Link to="/privacy-policy" className="block text-left mt-4 text-blue-900 underline hover:underline hover:text-blue-500" aria-label="Privacy Policy">
            Privacy Policy
          </Link>
          
        </div>
        <a
          href="#beerForm"
          className="fixed bottom-6 left-1/2 transform -translate-x-1/2 rounded-full bg-green-600 text-white p-4 sm:hidden"
          aria-label="Go to cheapest pint section"
        >
          Submit a pint ⬇️
        </a>
      </div>
    </Router>
  );
};

export default App;