// src/components/CookieModal.js
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const CookieModal = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!cookies.cookieConsent) {
      setShowModal(true);
    }
  }, [cookies.cookieConsent]);

  const handleAcceptAll = () => {
    setCookie('cookieConsent', 'accepted_all', { path: '/', maxAge: 31536000 }); // Cookie expires in 1 year
    setShowModal(false);
  };

  const handleRejectNonEssential = () => {
    setCookie('cookieConsent', 'accepted_essential', { path: '/', maxAge: 31536000 }); // Cookie expires in 1 year
    setShowModal(false);
  };

  const handleRejectAll = () => {
    setCookie('cookieConsent', 'rejected', { path: '/', maxAge: 31536000 }); // Cookie expires in 1 year
    setShowModal(false);
  };

  if (!showModal) return null;

  return (
    <div className="fixed top-0 left-0 right-0 bg-gray-800 text-white p-4 flex justify-center items-center z-50">
  <div className="backdrop-blur-md gap-12 p-6 rounded-lg shadow-lg text-center w-full flex flex-col md:flex-row items-center">
    <div className="flex-1 text-center md:text-left mb-4 md:mb-0">
      <h2 className="text-xl font-semibold mb-4">Cookie Notice</h2>
      <p className="">
      We use cookies on CheapestPint to enhance your browsing experience and analyze site usage to improve our services. Cookies also help us with marketing efforts. You have control over these cookies:
Accept All: Allow all cookies. Reject Non-Essential Cookies: Only necessary cookies will be used. Manage Settings: Customize your preferences. If you choose to share your location, please be assured that we do not use or store this data.
      </p>
      <p className="mb-4">
        For more details, please view our <a href="/privacy-policy" className="text-blue-400 underline">Privacy Policy</a>.
      </p>
    </div>
    <div className="flex flex-col gap-2">
      <button 
        onClick={handleAcceptAll} 
        className="bg-slate-600 hover:bg-slate-700 text-white py-2 px-4 rounded"
      >
        Accept All Cookies
      </button>
      <button 
        onClick={handleRejectNonEssential} 
        className="bg-slate-600 hover:bg-slate-700 text-white py-2 px-4 rounded"
      >
        Accept Essential Cookies Only
      </button>
      <button 
        onClick={handleRejectAll} 
        className="bg-slate-600 hover:bg-slate-700 text-white py-2 px-4 rounded"
      >
        Reject All Cookies
      </button>
    </div>
  </div>
</div>

  
  );
};

export default CookieModal;
